body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

html,
body,
#app,
#app > div {
  height: 100%;
  background-color: #f6f6f6;
}

.login {
  height: 100%;
  background-image: linear-gradient(315deg, #3f0d12 0%, #a71d31 74%);
  overflow: hidden;
}

.fc-popover {
  z-index: 5 !important;
}

.select-content {
  visibility: hidden;
}
